import React from 'react';

class Accordion extends React.Component {
    render() {
        return (
            <div>
                <h2 style={{marginBottom: '10px'}}>{this.props.title}</h2>
                <ul {...{className: 'accordion-list'}}>
                    {this.props.data.map((data, key) => {
                        return (
                            <li {...{className: 'accordion-list__item', key}}>
                                <AccordionItem {...data} id={key}/>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

class AccordionItem extends React.Component {
    render() {
        const {
            props: {
                text,
                title,
                id
            }
        } = this

        return (
            <div
                {...{
                    className: `accordion-item`
                }}
                role="button"
                tabindex={`${id}`}
                on={`tap:desc-${id}.toggleClass(class='accordion-item--opened')`}
            >
                <div {...{className: 'accordion-item__line'}}>
                    <h3 {...{className: 'accordion-item__title'}}>
                        {title}
                    </h3>
                    <span {...{className: 'accordion-item__icon'}}/>
                </div>
                <div {...{className: 'accordion-item__inner'}} id={`desc-${id}`}>
                    <div {...{className: 'accordion-item__content'}}>
                        <div className={'accordion-item__paragraph'} dangerouslySetInnerHTML={{__html: text}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Accordion